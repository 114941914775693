<template>
  <!-- Reusable input component -->
  <div>
    <vs-input
      v-if="disabled || readonly"
      :id="id"
      :name="name"
      :ref="inputRef"
      :placeholder="placeholder"
      :size="size"
      icon-pack="feather"
      :icon="icon ? 'icon-' + icon : ''"
      :disabled="disabled"
      :readonly="readonly"
      :value="inputValue"
      :class="['input-' + type, addClasses]"
      @focus.stop
      @keydown.stop
      @keyup.enter.stop
      @keyup.esc.stop
      @keyup.space.prevent
      @click.stop
    />
    <vs-input
      v-else
      v-model="inputValue"
      v-focus="focus"
      :id="id"
      :name="name"
      :ref="inputRef"
      :placeholder="placeholder"
      :type="typeLocal"
      :size="size"
      :autocomplete="autocomplete"
      :class="[
        'input-' + type,
        { 'border-danger': isError, 'is-changed': isChanged },
        addClasses
      ]"
      :disabled="disabled"
      :readonly="readonly"
      :maxlength="maxlength"
      :min="min"
      :step="step"
      icon-pack="feather"
      :icon="icon ? 'icon-' + icon : ''"
      icon-no-border
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
      @keydown="onKeydown"
      @keyup.enter="onEnter"
      @keyup.esc="onEsc"
      @keyup.space.prevent="onSpace"
      @keypress="onKeypress($event)"
      @click.stop="onClick"
      @paste="onPaste($event)"
    />

    <div v-if="type === 'password'" class="position-relative">
      <vs-icon
        v-if="!showPassword"
        icon-pack="feather"
        icon="icon-eye"
        class="position-absolute z-10"
        style="top: -26px; margin-right: 15px; right: 0"
        @mousedown.prevent
        @click.stop.prevent="showPassword = !showPassword"
      />
      <vs-icon
        v-else
        icon-pack="feather"
        icon="icon-eye-off"
        class="position-absolute z-10"
        style="top: -26px; margin-right: 15px; right: 0"
        @mousedown.prevent
        @click.stop.prevent="showPassword = !showPassword"
      />
    </div>
  </div>
  <!-- <div @keyup.enter.stop="$emit('enter')">
    <el-input
      v-model="inputValue"
      :type="type"
      :size="size"
      :disabled="disabled"
      :maxlength="maxlength"
      :class="{ 'border-danger': isError }"
    />
  </div> -->
</template>

<script>
export default {
  name: 'base-input',

  $_veeValidate: {
    // fetch the current value from the innerValue defined in the component data.
    value() {
      return this.inputValue;
    }
  },

  props: {
    value: {
      type: [String, Number],
      default: ''
      // required: true
    },
    id: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    autocomplete: {
      type: [String, Boolean],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md'
    },
    onlyNumbers: {
      type: Boolean,
      default: false
    },
    allowMinus: {
      type: Boolean,
      default: true
    },
    focus: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: [String, Number],
      default: ''
    },
    isError: {
      type: Boolean,
      default: false
    },
    isChanged: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    onEnterBlur: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: [Number, String],
      default: ''
    },
    min: {
      type: [Number, String],
      default: ''
    },
    step: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: null
    },
    addClasses: {
      type: [String, Object, Array],
      default: ''
    }
  },

  data() {
    return {
      showPassword: false
    };
  },

  computed: {
    inputRef() {
      return this.id ? this.id : 'base-input';
    },

    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('before-update', val);
        this.$emit('update:value', val);
      }
    },

    typeLocal() {
      if (this.type === 'password' && this.showPassword) {
        return 'input';
      }

      return this.type;
    }
  },

  methods: {
    fixDecimalValue(e, charAtNum = 0) {
      if (
        !this.onlyNumbers ||
        !this.$fnc.isNumber(this.inputValue) ||
        window.getSelection().toString() !== ''
      ) {
        return;
      }

      let dataMod = this.inputValue
        .toString()
        .replace(',', '.')
        .replace(/ /g, '');

      if (!dataMod.includes('.')) {
        return;
      }

      let temp = dataMod.split('.');
      this.inputValue = temp[0] + ',' + temp[1].charAt(charAtNum);
    },

    onEnter(evt) {
      if (this.onEnterBlur) {
        // programatically blur input
        this.$refs[this.id].$el.firstElementChild.blur();
      }

      this.$emit('enter', evt);
    },

    onEsc(evt) {
      this.$emit('esc', evt);
    },

    onSpace(evt) {
      this.$emit('space', evt);
    },

    onClick(evt) {
      this.$emit('click', evt);
    },

    onFocus(evt) {
      this.$emit('focus', evt);
    },

    onBlur(evt) {
      this.$emit('blur', evt);
    },

    onKeydown(evt) {
      this.$emit('keydown', evt);
    },

    onKeypress($event) {
      this.$emit('keypress', $event);

      if (this.onlyNumbers) {
        this.checkInputNumberValue($event);
      }
    },

    onInput(val) {
      this.$emit('input', val);
    },

    onPaste($event) {
      this.$emit('paste', $event);

      this.$nextTick(() => this.removeStringFromNumberValue($event));
    },

    checkInputNumberValue($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // do not check on enter
      if (keyCode === 13) {
        return;
      }

      /* if (
        window.getSelection().toString() !== '' &&
        !this.$fnc.isNumber($event.target.value)
      ) {
        $event.preventDefault();
      } */

      if (
        (window.getSelection().toString() === '' &&
          // if dot is pressed and comma is already there
          keyCode === 46 &&
          $event.target.value.includes(',')) ||
        // or if comma is pressed and dot is already there
        ($event.key == ',' && $event.target.value.includes('.'))
      ) {
        // prevent
        $event.preventDefault();
      }

      let not_allow_minus =
        !this.allowMinus ||
        keyCode !== 45 ||
        $event.target.value.indexOf('-') != -1;

      // only allow numbers and one dot/comma
      // TODO: find a way to prevent ¨
      if (
        // no keycodes outside num keycodes and §
        (keyCode < 48 || keyCode > 57 || keyCode === 167) &&
        // allow one dot
        // 46 is dot
        (keyCode !== 46 || $event.target.value.indexOf('.') != -1) &&
        // allow one comma
        ($event.key !== ',' || $event.target.value.indexOf(',') != -1) &&
        // allow one minus
        not_allow_minus &&
        // allow spaces
        keyCode !== 32
      ) {
        $event.preventDefault();
      }

      /* // Hide for now: restrict to 2 decimal places
      if (window.getSelection().toString() === '' && $event.target.value) {
        let t = $event.target.value.replace(',', '.');
        if (t.indexOf('.') > -1 && t.split('.')[1].length > 1) {
          $event.preventDefault();
        }
      } */

      /* old way to restrict to 2 decimal places: if (
        window.getSelection().toString() === '' &&
        $event.target.value &&
        (($event.target.value.indexOf('.') > -1 &&
          $event.target.value.split('.')[1].length > 1) ||
          ($event.target.value.indexOf(',') > -1 &&
            $event.target.value.split(',')[1].length > 1))
      ) {
        $event.preventDefault();
      } */
    },

    removeStringFromNumberValue($event) {
      setTimeout(() => {
        if (this.onlyNumbers) {
          const formattedNum = this.$fnc.roundToDecimals(
            $event.target.value,
            3
          );

          const isNum = this.$fnc.isNumber($event.target.value);
          if (!isNum || (!this.allowMinus && isNum && formattedNum < 0)) {
            $event.target.value = '';
            $event.preventDefault();
          }

          // if input has decimals and decimals length is > 2
          if (
            $event.target.value &&
            (($event.target.value.indexOf('.') > -1 &&
              $event.target.value.split('.')[1].length > 1) ||
              ($event.target.value.indexOf(',') > -1 &&
                $event.target.value.split(',')[1].length > 1))
          ) {
            $event.target.value = formattedNum;
          }

          this.inputValue = $event.target.value;
        }
      }, 1);
    },

    focusManually() {
      this.$nextTick(() =>
        this.$refs[
          this.inputRef
        ]?.$el.firstElementChild.firstElementChild.focus()
      );
    }
  }
};
</script>

<style lang="scss">
.input-password div input {
  padding-right: 40px !important;
}
.is-changed div input {
  border: 1px solid #ff9f43 !important;
}
</style>
