export default {
  bind(el, binding, vnode) {
    // Define the click handler
    el.clickOutsideEvent = function (event) {
      // Check if the click was outside the element
      if (!(el == event.target || el.contains(event.target))) {
        // Call the method provided in the binding expression
        vnode.context[binding.expression](event);
      }
    };
    // Add the event listener to the document
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    // Remove the event listener from the document
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
};
