/*=========================================================================================
  File Name: user.js
  Description: actions requests to user.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'user';

const actions = {
  tasks: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'tasks');

    commit('set', ['masterdata', 'workgroups', data.data['workgroups']], {
      root: true
    });
    commit(
      'set',
      ['masterdata', 'workflowStatuses', data.data['workflow_statuses']],
      {
        root: true
      }
    );
    commit(
      'set',
      [
        'masterdata',
        'workflowControlTypes',
        data.data['workflow_control_types']
      ],
      {
        root: true
      }
    );

    return data.data;
  },

  'returned-task': async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'returned-task', payload);

    return data.data;
  },

  usersheet: async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'usersheet', payload);

    return data.data;
  },

  'usersheet-save': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'usersheet-save', payload);

    return data.saved;
  },

  'usersheet-update': async (context, payload) => {
    const data = await Vue.$api.postFiles(
      moduleName,
      'usersheet-update',
      payload.files,
      payload.data
    );

    return data;
  },

  'usersheet-remove': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'usersheet-remove', payload);

    return data.success;
  },

  'holiday-list': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'holiday-list', payload);

    commit('set', ['masterdata', 'users', data.data['users']], {
      root: true
    });

    return data.data;
  },

  'holiday-save': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'holiday-save', payload);

    return data.saved;
  },

  'holiday-approve': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'holiday-approve', payload);

    return data.saved;
  },

  'event-cancel': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'event-cancel', payload);

    return data.success;
  },

  'tracker-modal': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'tracker-modal', payload);

    return data;
  },

  'holidays-overview': async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'holidays-overview', payload);

    return data;
  },

  'holidays-user-settings': async ({ commit }, payload) => {
    const data = await Vue.$api.get(
      moduleName,
      'holidays-user-settings',
      payload
    );

    commit('set', ['masterdata', 'users', data['users']], {
      root: true
    });

    return data;
  },

  'holidays-user-settings-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'holidays-user-settings-save',
      payload
    );

    return data.saved;
  },

  'get-user-holiday-homeoffice-list': async (context, payload) => {
    const data = await Vue.$api.get(
      moduleName,
      'get-user-holiday-homeoffice-list',
      payload
    );

    return data;
  },

  notifications: async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'notifications', payload);

    return data;
  }
};

export default {
  namespaced: true,
  actions
};
