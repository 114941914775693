<template>
  <div>
    <!-- {{ inputValue }} ?? {{ customTimeValue }} -->
    <el-time-picker
      v-if="type === 'time'"
      ref="baseDatepicker"
      v-model="inputValue"
      :name="name"
      :format="formatLocal"
      :value-format="valueFormatLocal"
      :disabled="disabled"
      :size="size"
      :placeholder="placeholder"
      :clearable="false"
      :class="[{ 'is-changed': isChanged }, addClasses]"
      class="w-full"
      @change="onTimeChange"
      @focus="onTimeFocus"
      @blur="onTimeBlur"
    />
    <el-date-picker
      v-else
      ref="baseDatepicker"
      v-model="inputValue"
      :name="name"
      :type="type"
      :picker-options="{ firstDayOfWeek: 1 }"
      :format="formatLocal"
      :value-format="valueFormatLocal"
      :focus="true"
      :disabled="disabled"
      :size="size"
      :placeholder="placeholder"
      :clearable="clearable"
      :class="[{ 'is-changed': isChanged }, addClasses, size]"
      class="w-full"
      range-separator="-"
      start-placeholder="Datum od"
      end-placeholder="Datum do"
      @change="$emit('submit', inputValue)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
      <!-- default-time="12:00:00" -->
    </el-date-picker>
    <!-- <flat-pickr slot="date_from" :config="configFromdateTimePicker" v-model="reportData.date_from" placeholder="-- zvolte čas od --" @on-change="onFromChange"/>
            <flat-pickr slot="date_to" :config="configTodateTimePicker" v-model="reportData.date_to" placeholder="-- zvolte čas do --" @on-change="onToChange" /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import fnc from '@/inc/funkce'
// import flatPickr from 'vue-flatpickr-component'
// import 'flatpickr/dist/flatpickr.min.css'
// import 'flatpickr/dist/flatpickr.css';
// import {Czech as CzechLocale} from 'flatpickr/dist/l10n/cs.js'

export default {
  name: 'BaseDatepicker',

  /* components: {
      flatPickr
  }, */

  props: {
    value: {
      type: [String, Array], // string pro datetime, array pro datetimerange,
      default: ''
    },
    name: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default: ''
    },
    valueFormat: {
      type: String,
      default: ''
    },
    dateForTime: {
      type: String
    },
    disabled: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    size: { type: String, default: 'md' },
    placeholder: { type: String, default: '-- vyberte datum --' },
    pickerOption: { type: Boolean, default: false },
    programaticOpen: { type: Boolean, default: false },
    isChanged: {
      type: Boolean,
      default: false
    },
    addClasses: {
      type: [String, Object, Array],
      default: ''
    }
  },

  data() {
    return {
      isProgramaticOpen: false,
      customTimeValue: undefined

      //proměnné pro změnu jazyka kalendáře
      // months: ["leden", "únor", "březen", "duben", "květen", "červen",
      // "červenec", "srpen", "září", "říjen", "listopad", "prosinec"],
      //value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      /* pickerOptions: {
        shortcuts: [
          {
            text: 'Poslední týden',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Poslední měsíc',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Poslední 3 měsíce',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      } */
      /* pickerOptions: {
        shortcuts: [
          {
            text: 'Dnes',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, 
          {
            text: 'Yesterday',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, 
          {
            text: 'A week ago',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }
        ]
      } */
      /* configdateTimePicker: {
          enableTime: true,
          locale: CzechLocale,
          dateFormat: "d.m.Y H:i"
      }, */
      /* configFromdateTimePicker: {
          noCalendar: this.orderId ? false : true,
          enableTime: true,
          enableSeconds: true,
          time_24hr: true,
          minDate: null,
          maxDate: null,
          allowInput: true,
          locale: CzechLocale,
          altFormat: this.orderId ? 'd.m.Y H:i:ss' : 'H:i:ss',
          altInput: true,
          altInputClass: 'flatpickr-input',
          //dateFormat: "Y-m-d H:i"
      }, */
      /* configTodateTimePicker: {
          noCalendar: this.orderId ? false : true,
          enableTime: true,
          enableSeconds: true,
          time_24hr: true,
          minDate: null,
          allowInput: true,
          locale: CzechLocale,
          altFormat: this.orderId ? 'd.m.Y H:i:ss' : 'H:i:ss',
          altInput: true,
          altInputClass: 'flatpickr-input',
          //dateFormat: this.orderId ? 'Y-m-d H:i:ss' : 'H:i:ss'
      }, */
    };
  },

  computed: {
    ...mapGetters(['DEFAULT_DATETIME']),

    inputValue: {
      get() {
        //if(!this.value) return new Date()
        return this.value !== this.DEFAULT_DATETIME ? this.value : '';
      },
      set(val) {
        if (!val) {
          // kdyz neni moznost smazani datumu, tak po odmazu uzivatelem nastavime na dnesek..
          if (!this.clearable) {
            val = this.$fnc.dateTimeFormat(new Date(), {
              has_default_time: true
            });
          } else {
            val = '';
          }
        }

        this.$emit('before-update', val);
        this.$emit('update:value', val);
      }
    },

    formatLocal() {
      if (this.format) {
        return this.format;
      }

      if (this.type === 'datetime') {
        return 'dd.MM.yyyy HH:mm';
      }

      if (this.type === 'year') {
        return 'yyyy';
      }

      if (this.type === 'month') {
        return 'MM.yyyy';
      }

      if (this.type === 'time') {
        return 'HH:mm';
      }

      if (this.type === 'time_with_sec') {
        return 'HH:mm:ss';
      }

      return 'dd.MM.yyyy';
    },

    valueFormatLocal() {
      if (this.valueFormat) {
        return this.valueFormat;
      }

      if (this.type === 'datetime') {
        return 'yyyy-MM-dd HH:mm:ss';
      }

      if (this.type === 'year') {
        return 'yyyy';
      }

      // for time, we want to send datetime to php
      //  fixed with onTimeChange
      if (this.type === 'time') {
        return 'HH:mm';
        //  cannot do it like this, date is 1st Jan of current year..
        // return 'yyyy-MM-dd HH:mm:ss';
      }

      if (this.type === 'time_with_sec') {
        return 'HH:mm:ss';
      }

      return 'yyyy-MM-dd';
    }
  },

  watch: {
    isProgramaticOpen(val) {
      this.$refs.baseDatepicker.showPicker();
    },

    inputValue(val) {
      if (this.customTimeValue !== undefined) {
        this.customTimeValue = val;
      }
    }
  },

  mounted() {
    this.isProgramaticOpen = this.programaticOpen;
  },

  methods: {
    onTimeFocus() {
      // aby si lidi mohli napsat cas jako 4 cisla
      // musi to byt takto saskofsky protoze el-time-picker nema event input..
      this.customTimeValue = this.inputValue;
      // Old approach: document.addEventListener('input', this.handleWindowInput);
      const baseDatepicker = this.$refs['baseDatepicker'];
      if (baseDatepicker) {
        const baseDatepickerInput = baseDatepicker?.$el?.firstElementChild;
        if (baseDatepickerInput) {
          baseDatepickerInput.addEventListener('input', this.handleWindowInput);

          // selekt na input
          setTimeout(() => {
            baseDatepickerInput.focus();
            baseDatepickerInput.select();
          }, 10);
        }
      }

      this.$emit('focus');
    },

    onTimeBlur() {
      if (this.customTimeValue !== undefined) {
        let d = '';
        if (this.$fnc.isAnyDateFormat(this.dateForTime)) {
          d = new Date(this.dateForTime);
        } else {
          d = new Date();
        }

        let time = '00:00';
        if (this.$fnc.isValidTime(this.customTimeValue, true)) {
          // console.log('a');
          time = this.customTimeValue;
        } else if (
          this.customTimeValue.includes(':') &&
          this.$fnc.isValidTime(this.customTimeValue)
        ) {
          // console.log('b');
          time = this.customTimeValue;
        } else if (
          this.customTimeValue.length === 4 &&
          this.$fnc.isNumber(this.customTimeValue)
        ) {
          const parts = this.customTimeValue.split(' ');
          // Get the time part from the second element
          if (
            this.$fnc.isAnyDateFormat(this.customTimeValue) &&
            parts.length > 1
          ) {
            // console.log('c');
            time = parts[1];
          } else {
            // console.log('d');
            time = this.$fnc.formatTimeFromNumber(this.customTimeValue);
          }
        }
        // console.log(time);

        if (!this.$fnc.isValidTime(time, true)) {
          time += ':00';
        }

        const dateAndTimeArr = [this.$fnc.dateFormat(d), time];
        this.inputValue = dateAndTimeArr.join(' ');
      }

      // Old approach: document.removeEventListener('input', this.handleWindowInput);
      const baseDatepicker = this.$refs['baseDatepicker'];
      if (baseDatepicker) {
        const baseDatepickerInput = baseDatepicker?.$el?.firstElementChild;
        if (baseDatepickerInput) {
          baseDatepickerInput.removeEventListener(
            'input',
            this.handleWindowInput
          );
        }
      }
      this.customTimeValue = undefined;
      this.$emit('blur');
    },

    onTimeChange() {
      let d = '';
      if (this.$fnc.isAnyDateFormat(this.dateForTime)) {
        d = new Date(this.dateForTime);
      } else {
        d = new Date();
      }

      let time = '';
      const parts = this.inputValue.split(' ');
      // Get the time part from the second element
      if (parts.length > 1) {
        time = parts[1];
      } else {
        time = this.inputValue;
      }

      const dateAndTimeArr = [this.$fnc.dateFormat(d), time];
      this.inputValue = dateAndTimeArr.join(' ');

      this.$emit('submit', this.inputValue);
    },

    handleWindowInput(e) {
      const elTarget = e.target;
      if (elTarget.className === 'el-input__inner') {
        elTarget.maxLength = 5;
        if (elTarget.value.length > elTarget.maxLength) {
          elTarget.value = elTarget.value.slice(0, -1);
        }

        // console.log(e);
        // console.log(elTarget.value);
        this.customTimeValue = elTarget.value;
      }
    }

    /* onFromChange(selectedDates, dateStr, instance) {
          this.$set(this.configTodateTimePicker, 'minDate', dateStr);
        },
        onToChange(selectedDates, dateStr, instance) {
            this.$set(this.configFromdateTimePicker, 'maxDate', dateStr);
        }, */
  }
};
</script>

<style lang="scss">
.is-changed .el-input__inner {
  border-color: #ff9f43 !important;
}
.md.el-date-editor--daterange {
  height: 38px !important;
}
.el-date-editor.rounded-left .el-input__inner {
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.el-date-editor.rounded-right .el-input__inner {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
</style>
