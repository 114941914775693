<template>
  <div class="base-table">
    <!-- <vs-navbar type="flat" v-if="pagination">
        <div slot="title">
        </div>

        <vs-navbar-item index="3" class="flex items-center mb-2">
            <div class="mr-3">Počet řádků:</div>
            <vs-input-number size="medium" v-model="itemsMax" @input="itemsMax < 2 ? itemsMax = 1 : ''"/>
        </vs-navbar-item>
    </vs-navbar> -->
    <!-- <div class="flex items-center mb-2 text-right w-full" v-if="pagination">
        <vs-spacer />
        <div class="mr-3">Počet řádků:</div>
        <vs-input v-model="itemsMax" @input="itemsMax < 2 ? itemsMax = 1 : ''" style="width: 40px; "></vs-input>
        <vs-input-number size="medium" v-model="itemsMax" @input="itemsMax < 2 ? itemsMax = 1 : ''"/>
    </div> -->
    <base-card
      :class="{ 'mb-2': paddingBottom }"
      :add-classes-header="{ 'has-sticky-header': hasStickyHeader }"
      :add-styles-header="
        hasStickyHeader ? 'top: ' + stickyHeaderPos + 'px;' : ''
      "
      :header="showHeader"
      :card="card"
    >
      <template slot="header">
        <!-- <h4>{{theader}}</h4> -->
        <div
          class="mx-1 inline-flex w-full align-items-center"
          v-if="showHeader || (filterable && canUseQueryFilter)"
        >
          <div class="text-lg inline-flex align-items-center">
            <slot name="header" />
          </div>

          <vs-spacer />

          <slot name="header-items" />

          <el-input
            v-if="filterable && filterKeys.length > 0 && canUseQueryFilter"
            v-model="searchQuery"
            size="small"
            style="width: 200px"
            class="lg:ml-2"
            placeholder="Vyhledávání"
          />

          <!-- <template v-if="pagination">
            <div class="mx-2 my-auto font-semibold text-sm">
              Počet řádků:
            </div>
            <vs-input-number
              v-model="itemsMax"
              size="medium"
              @input="itemsMax < 2 ? (itemsMax = 1) : ''"
            />
          </template> -->
        </div>
      </template>

      <div v-if="$scopedSlots['over-table']" class="mb-2">
        <slot name="over-table" />
      </div>

      <vs-table
        :id="tableId"
        :key="tableId"
        :data="tableItemsWithPagination"
        :no-data-text="null"
        :class="[tableClasses, { 'table-body-inherit': tableBodyInherit }]"
        :pagination="false"
        :max-items="itemsMax"
        hover-flat
        class="bg-white border-radius-none h-full table-hover"
        ><!-- { 'p-2 border': !noPadding } -->
        <template slot="thead" v-if="!noTableHeader">
          <!-- <vs-th v-for="(nameB, b) in headerBefore" :key="'before-' + b">{{nameB}}</vs-th> -->
          <th v-if="draggableItems" />

          <template v-for="(itemLabel, i) in tableFields">
            <slot :name="'th-' + itemLabel.key">
              <th
                :key="itemLabel.key + '-' + i"
                :id="itemLabel.key + '-' + i"
                :class="['pre-line', itemLabel._classes, itemLabel._th_classes]"
                :style="itemLabel._styles + ';' + itemLabel._th_styles"
                style="height: 30px"
              >
                <div
                  :class="{
                    'd-flex align-items-center justify-content-between':
                      itemLabel.label_info
                  }"
                >
                  <slot :name="'th-value-' + itemLabel.key">
                    {{ itemLabel.label }}

                    <feather-icon
                      v-if="itemLabel.label_info"
                      v-tooltip="itemLabel.label_info"
                      icon="InfoIcon"
                      svg-classes="h-4 w-4 text-primary ml-2 mb-1"
                    />
                  </slot>
                </div>
              </th>
              <!-- class="truncate" -->
            </slot>
          </template>
          <!-- <vs-th v-for="(nameA, a) in itemsAfter" :key="'after-' + a">{{nameA}}</vs-th> -->
        </template>

        <template slot-scope="{ data }">
          <tbody ref="base-table-body" :id="draggableGroupId">
            <template v-for="(item, itemIndex) in data">
              <slot :item="item" :index="itemIndex" name="vs-tr">
                <tr
                  v-if="$fnc.isObject(getItem(item))"
                  :class="[
                    {
                      'cursor-pointer': clickable && clickableCursorPointer,
                      'bg-light': itemIndex % 2 === 0
                    },
                    getItem(item)._classes
                  ]"
                  class="tr-values vs-table--tr tr-table-state-null hoverFlat"
                  :key="
                    draggableItems && getItem(item).id > 0
                      ? getItem(item).id
                      : getItem(item).id > 0
                      ? itemIndex + '-' + getItem(item).id
                      : itemIndex
                  "
                  @click.stop="handleRowClick(item)"
                  @dblclick="$emit('row-dblclick', item)"
                  @keyup.esc.stop="$emit('row-esc')"
                >
                  <!-- <template v-if="headerBefore">
                        <vs-td v-for="(numB, b) in headerBefore" :key="'before-' + b">
                            <slot :name="'before-' + b"/>
                        </vs-td>
                    </template> -->

                  <td class="vs-table--td" v-if="draggableItems">
                    <feather-icon
                      icon="MenuIcon"
                      class="d-flex my-auto w-5 h-5 cursor-pointer drag-handler"
                    />
                  </td>

                  <template v-for="field in tableFields">
                    <slot
                      :name="'td-' + field.key"
                      :item="item"
                      :index="itemIndex"
                    >
                      <vs-td
                        :key="field.key + '-' + getItem(item).id"
                        :class="[field._classes, field._td_classes]"
                        :style="field._styles + ';' + field._td_styles"
                        v-tooltip="getItem(item)[field.key_tooltip] ?? ''"
                      >
                        <slot :name="field.key" :item="item" :index="itemIndex">
                          <template v-if="field.formatter === 'currency'">
                            {{
                              $fnc.currencyFormatter(getItem(item)[field.key])
                            }}
                          </template>

                          <template v-else-if="field.formatter === 'number'">
                            {{ $fnc.numberFormatter(getItem(item)[field.key]) }}
                          </template>

                          <template
                            v-else-if="field.formatter === 'trim_number'"
                          >
                            {{ $fnc.trimNumber(getItem(item)[field.key]) }}
                          </template>

                          <span
                            v-else-if="field.formatter === 'sec_to_time'"
                            v-tooltip="
                              $fnc.secToTime(getItem(item)[field.key], true)
                            "
                          >
                            {{ $fnc.secToTime(getItem(item)[field.key]) }}
                          </span>

                          <span
                            v-else-if="
                              field.formatter === 'sec_to_time_round_minutes'
                            "
                            v-tooltip="
                              $fnc.secToTime(getItem(item)[field.key], true)
                            "
                          >
                            {{
                              $fnc.secToTime(
                                getItem(item)[field.key],
                                false,
                                true
                              )
                            }}
                          </span>

                          <template v-else-if="field.formatter === 'date'">
                            <template
                              v-if="!IS_DEFAULT_DATE(getItem(item)[field.key])"
                            >
                              {{ $fnc.dateToCz(getItem(item)[field.key]) }}
                            </template>
                          </template>

                          <template v-else>{{
                            getItem(item)[field.key]
                          }}</template>
                        </slot>
                      </vs-td>
                    </slot>
                  </template>

                  <!-- <template v-if="itemsAfter">
                        <vs-td v-for="(numA, a) in itemsAfter" :key="'after-' + a">
                            <slot :name="'after-' + a" :item="item" />
                            <div @click="$emit('click-' + a, item)"><slot :name="'after-' + a" :item="item" /></div>
                        </vs-td>
                    </template> -->
                </tr>
              </slot>

              <tr
                v-if="showUnderItem"
                :key="'under-item-' + itemIndex + '-' + getItem(item).id"
                class="under-item"
              >
                <slot name="tr-under-item" :item="item" :index="itemIndex" />
              </tr>
            </template>
          </tbody>

          <!-- vlastni posledni radek -->
          <tbody v-if="$slots['tbody-tr-bottom']">
            <vs-tr
              class="cursor-auto font-weight-bold no-wrap"
              :key="'tbody-tr-bottom-' + tableId"
              @click.stop
            >
              <td class="vs-table--td" v-if="draggableItems" />
              <slot name="tbody-tr-bottom" />
            </vs-tr>
          </tbody>

          <tbody v-if="addNewItem" class="table-footer">
            <vs-tr>
              <vs-td
                v-for="(field, i) in newFields"
                :key="i"
                :class="[field._classes, field._td_classes]"
                :style="field._styles + ';' + field._td_styles"
              >
                <slot :name="'new-' + field.key">
                  <template v-if="field.component === 'input'">
                    <!-- v-tooltip.right="checkRestrict(field.restrict, items[field.key]) ? 'jen čísla': false" -->
                    <base-input
                      :value.sync="newItem[field.key]"
                      :name="'new-' + field.key"
                      :type="field.type"
                      :disabled="field.disabled"
                      :maxlength="field.maxlength"
                      :only-numbers="field.onlyNumbers"
                      :step="field.step"
                      :min="field.min"
                      add-classes="w-full"
                      size="small"
                      @enter="$emit('save-new-item')"
                      @esc="$emit('edit-esc')"
                    />
                  </template>

                  <template v-else-if="field.component === 'select'">
                    <div @keyup.enter.stop>
                      <el-select
                        v-model="newItem[field.key]"
                        class="w-full"
                        placeholder=""
                        no-data-text=" "
                        clearable
                        filterable
                        default-first-option
                        size="mini"
                      >
                        <el-option
                          v-for="(opt, index) in [
                            { label: '--vyberte--', value: 0 },
                            ...field.componentData
                          ]"
                          :key="index + '' + opt.value"
                          :label="String(opt.label)"
                          :value="
                            $fnc.isNumber(opt.value)
                              ? Number(opt.value)
                              : opt.value
                          "
                        >
                          <span style="float: left">{{ opt.label }}</span>
                        </el-option>
                      </el-select>
                    </div>
                  </template>

                  <template v-else-if="field.component === 'checkbox'">
                    <el-checkbox
                      v-model="newItem[field.key]"
                      :true-label="isChecked"
                      :false-label="notChecked"
                      class="my-auto"
                    />
                  </template>
                </slot>
              </vs-td>
              <vs-td v-if="!noSaveButton">
                <div class="button-group">
                  <base-button
                    class="text-white"
                    size="small"
                    icon="check"
                    @keyup.enter.stop
                    @click="$emit('save-new-item')"
                  />
                  <base-button
                    v-if="newItem && newItem.id"
                    size="small"
                    color="danger"
                    icon="x"
                    @keyup.enter.stop
                    @click="$emit('edit-esc')"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
          <!-- <tbody v-if="addNewItem" @keyup.enter="$emit('save-new-item')">
                <vs-tr>
                    <vs-td v-for="(num, i) in addNewItem" :key="i">
                        <slot :name="'new-' + i" />
                    </vs-td>
                </vs-tr>
            </tbody> -->
        </template>
      </vs-table>

      <base-row class="justify-content-center mt-3" v-if="pagination">
        <!-- && items.length > 10 -->
        <base-col sm="auto" class="pr-1">
          <vs-pagination
            :total="totalPages"
            v-model="activePageCurrent"
            class="pagination-sm"
          ></vs-pagination>
        </base-col>

        <base-col sm="auto" class="pl-1">
          <base-select
            :options="
              $fnc.createSelectOptions(itemsPerPageSelect, 'value', 'value')
            "
            :value.sync="itemsPerPageCurrent"
            :clearable="false"
            no-default-value
            size="small"
            style="width: 80px"
          />
        </base-col>
      </base-row>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Sortable } from 'sortablejs';

/* USAGE IN COMPONENTS: 
    fields: define table head key + label // add _classes and/or _styles to define column items
        eg. fields: [
            { key: 'test1', label: 'Test 1', _classes: classTest, _styles: 'background: green' },
            { key: 'test2', label: 'Test 2' },
            ...
        ],
    items: data to fill table
        eg. items: [
            { test1: 'item1-1', test2: 'item1-2'},
            { test1: 'item2-1', test2: 'item2-2' },
            ...
        ],
    pagination: define the usage of pagination,
    max-items: max items per page,
    clickable: define if row is clickable,
    @row-clicked: what happens after row click,
    addNewItem: adds new row, 
    use <template #fieldKey="props"> ... </template> inside component to display anything else than item data of column items,
    
    composition example:
        <base-table 
            :fields
            :items
            theader
            pagination
            max-items
            clickable
            @row-clicked
            addNewItem
        >
            <template slot="header">
                Hello World!
            </template>
            <template #test1="props">
                <div 
                    :class="{
                        'bg-success': props.item.active == 'Y',
                        'bg-danger': props.item.active == 'N'
                    }" 
                    @click.stop="testFnc(props.item)"
                />
            </template>
        </base-table>
*/

export default {
  name: 'BaseTable',

  props: {
    tableId: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      required: true
    },
    items: {
      type: [Array, Object, Boolean],
      required: true,
      default: () => []
    },
    pagination: {
      type: Boolean,
      default: false
    },
    // vlastni definice poctu radku // plati pak tato promenna misto items.length
    paginationTotalRows: {
      type: [String, Number],
      default: 0
    },
    activePage: {
      type: [String, Number],
      default: 1
    },
    pageLimit: {
      type: [String, Number],
      default: 100
    },
    maxItems: {
      type: Number,
      default: 0
    },
    clickable: {
      type: Boolean,
      default: false
    },
    clickableCursorPointer: {
      type: Boolean,
      default: true
    },
    addNewItem: {
      type: Boolean,
      default: false
    },
    newFields: {
      type: Array,
      default: () => []
    },
    newItem: {
      type: Object,
      default: () => {}
    },
    noSaveButton: {
      type: Boolean,
      default: false
    },
    tableClasses: {
      type: String,
      default: ''
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    noTableHeader: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    card: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: false
    },
    filterKeys: {
      type: Array,
      default: () => ['name']
    },
    paddingBottom: {
      type: Boolean,
      default: true
    },
    // modul // pro draggableItems, removableItems
    moduleName: {
      type: String,
      default: ''
    },
    // moznost prerazeni radku // musi byt definovan moduleName
    draggableItems: {
      type: Boolean,
      default: false
    },
    draggableAction: {
      type: String,
      default: ''
    },
    draggableGroup: {
      type: String
    },
    draggableGroupId: {
      type: [String, Number]
    },
    showUnderItem: {
      type: Boolean,
      default: false
    },
    itemKey: {
      type: String,
      default: ''
    },
    hasStickyHeader: {
      type: Boolean,
      default: false
    },
    stickyHeaderPos: {
      type: [Number, String],
      default: 0
    },
    tableBodyInherit: {
      type: Boolean,
      default: false
    }
    /* theader: {
            type: String
        }, */
    /* headerBefore: {
            type: Array
        },
        itemsAfter: {
            type: Array
        }, */
  },

  data() {
    return {
      maxItemsToFilter: 5000,

      activePageCurrent: 1,
      itemsPerPageCurrent: 100,
      itemsPerPageSelect: [
        { value: '20' },
        { value: '50' },
        { value: '100' },
        { value: '500' }
      ],
      searchQuery: '',
      itemsMax: this.maxItems
        ? this.maxItems
        : this.$store.getters['MAX_ITEMS'],
      isChecked: 'Y',
      notChecked: 'N'
    };
  },

  computed: {
    ...mapGetters(['NO_DATA_TEXT', 'IS_DEFAULT_DATE']),

    getItem() {
      return (item) => {
        if (!this.$fnc.isStringEmpty(this.itemKey)) {
          return item[this.itemKey];
        }

        return item;
      };
    },

    // povolime fe vyhledavani jen kdyz je pocet zaznamu mensi nez maxItemsToFilter
    canUseQueryFilter() {
      if (this.maxItemsToFilter > this.tableItemsArray.length) {
        return true;
      }

      return false;
    },

    tableFields() {
      return this.fields.filter(Boolean);
    },

    tableItemsArray() {
      if (this.$fnc.isObject(this.items)) {
        return Object.values(this.items);
      } else {
        return this.items;
      }
    },

    tableItemsWithFilter() {
      let tableItems = this.tableItemsArray;

      if (
        this.filterable &&
        this.filterKeys.length > 0 &&
        this.searchQuery &&
        this.canUseQueryFilter
      ) {
        let filteredItems = [];

        this.filterKeys.forEach((key) => {
          tableItems.filter((item) => {
            if (
              !this.$fnc.arrayFind(filteredItems, 'id', item.id) &&
              this.$fnc.matchLowerCase(item[key], this.searchQuery)
            ) {
              filteredItems.push(item);
            }
          });
        });

        tableItems = filteredItems;
      }

      return tableItems.filter(Boolean);
    },

    tableItemsWithPagination() {
      if (this.pagination && this.paginationTotalRows == 0) {
        return this.tableItemsWithFilter
          .slice((this.activePageCurrent - 1) * this.itemsPerPageCurrent)
          .slice(0, this.itemsPerPageCurrent);
      }

      return this.tableItemsWithFilter;
    },

    totalPages() {
      if (this.pagination && this.paginationTotalRows > 0) {
        return Math.ceil(this.paginationTotalRows / this.itemsPerPageCurrent);
      }

      return Math.ceil(
        this.tableItemsWithFilter.length / this.itemsPerPageCurrent
      );
    },

    activePageAndItemsPerPageWatcher() {
      return this.activePageCurrent + '|' + this.itemsPerPageCurrent;
    }
  },

  watch: {
    activePageAndItemsPerPageWatcher(val) {
      let temp = this.$fnc.splitText(val, '|');
      let aPage = temp[0]; // active page as index
      let iPage = temp[1]; // items per page

      let temp2 = { page: aPage, limit: iPage };

      this.$emit('update-list', temp2);
    },

    totalPages(val) {
      // if total pages is 1
      // reset active page to 1
      if (val == 1) {
        this.activePageCurrent = 1;
      }
    }
  },

  mounted() {
    this.activePageCurrent = Number(this.activePage);
    this.itemsPerPageCurrent = Number(this.pageLimit);

    this.makeItemsDraggable();
  },

  updated() {
    this.makeItemsDraggable();
  },

  methods: {
    handleRowClick(item) {
      if (!this.clickable || window.getSelection().toString() !== '') {
        return false;
      }

      this.$emit('row-clicked', item);
    },

    makeItemsDraggable() {
      // pro možnost uspořádat seznam drag n drop
      if (this.draggableItems) {
        const el = this.$refs['base-table-body'];

        if (el !== null) {
          Sortable.create(el, {
            group: this.draggableGroup,
            handle: '.drag-handler',
            animation: 200,
            onEnd: this.onEnd
          });
        }
      }
    },

    async onEnd(evt) {
      /* console.log({
        item_from_bookmark: evt.from.id,
        item_to_bookmark: evt.from.id
      }); */
      //this.sortTabs.option('disabled', false))
      let itemsOnPreviousPages =
        (this.activePageCurrent - 1) * this.itemsPerPageCurrent; // itemsOnPreviousPages kvuli pagination
      let oldIndex = evt.oldIndex + itemsOnPreviousPages;
      let newIndex = evt.newIndex + itemsOnPreviousPages;

      let payload = {};

      if (this.draggableGroup) {
        const itemFromGroup = evt.from.id;
        const itemToGroup = evt.to.id;

        if (itemFromGroup === itemToGroup && oldIndex === newIndex) {
          return;
        }

        payload = {
          item_from_group: itemFromGroup,
          item_to_group: itemToGroup
        };
      } else if (oldIndex === newIndex) {
        return;
      }

      const itemFrom = this.items[oldIndex];
      const itemTo = this.items[newIndex];

      if (!this.$fnc.isStringEmpty(this.draggableAction)) {
        await this.$store.dispatch(this.draggableAction, {
          item_from: itemFrom.id,
          item_to: itemTo.id,
          ...payload
        });
        this.$emit('fetch');
      } else {
        this.$emit('on-dragged', {
          item_from: itemFrom,
          item_to: itemTo,
          item_from_index: oldIndex,
          item_to_index: newIndex,
          ...payload
        });
      }
    }

    /* async makeItemsDraggable() {
      // pro možnost uspořádat seznam drag n drop // musi byt definovan moduleName !!
      if (
        this.tableId &&
        this.draggableItems &&
        this.moduleName &&
        this.moduleName !== ''
      ) {
        let el = document.querySelector('#' + this.tableId + ' tbody');
        // console.log(el)
        if (el !== null) {
          Sortable.create(el, {
            animation: 200,
            onEnd: this.onEnd
          });
        }
      }
    },

    async onEnd(evt) {
      //this.sortTabs.option('disabled', false))
      let itemsOnPreviousPages =
        (this.activePageCurrent - 1) * this.itemsPerPageCurrent; // itemsOnPreviousPages kvuli pagination
      let oldIndex = evt.oldIndex + itemsOnPreviousPages;
      let newIndex = evt.newIndex + itemsOnPreviousPages;

      if (oldIndex === newIndex) return;

      let itemFrom = this.items[oldIndex].id;
      let itemTo = this.items[newIndex].id;

      // this.$emit('change-posindex', {item_from: itemFrom, item_to: itemTo})
      await this.$store.dispatch(this.moduleName + '-items-sorting', {
        item_from: itemFrom,
        item_to: itemTo
      });
      this.$emit('updated');
    } */

    /* paginator(items, current_page, per_page_items) {
        console.log(this.items)
        let page = this.activePage || 1,
        per_page = this.itemsPerPage || 10,
        offset = (page - 1) * per_page,

        paginatedItems = this.items.slice(offset).slice(0, this.itemsPerPage),
        total_pages = Math.ceil(this.items.length / per_page);

        this.totalPages = total_pages
        this.tableItems = paginatedItems

        return {
          page: page,
          per_page: per_page,
          pre_page: page - 1 ? page - 1 : null,
          next_page: (total_pages > page) ? page + 1 : null,
          total: this.items.length,
          total_pages: total_pages,
          data: paginatedItems
        };
      } */

    /* $eval(cond, item, keys) {
            console.log(item)
            if(!keys) return
            let temp = ''
            keys.forEach(i => {
                temp += cond.replace('a', i)
            })
            return eval(temp)
        } */
  }
};
</script>

<style lang="scss">
/* div.base-table table.vs-table tbody > tr:not([class*='bg-']):nth-of-type(odd) {
  background-color: #ebedef;
} */
td.vs-table--td,
.vs-table--thead th {
  border: 0.01rem solid #d8dbe0;
}
div.base-table table.vs-table {
  // width: calc(100% - 0.225rem) !important;
  margin: 0 auto;
  border-collapse: collapse;
  border-spacing: 0;
}

.vs-table--not-data {
  padding: 0;
}
.table-hover tbody tr:not(.under-item):hover td,
.table-hover tbody tr:hover th {
  background-color: rgba(245, 245, 245, 0.5);
  /* background-color: #f5f5f5; */
}
.table-hover tbody tr.under-item:hover {
  background-color: initial !important; /* or transparent or whatever the default color should be */
  color: initial !important; /* or the default color */
}

.table-body-inherit {
  .vs-table--tbody {
    position: inherit;
  }
}

@media screen and (min-width: 1200px) {
  .has-sticky-header {
    position: sticky;
    z-index: 999;
  }
}

/* .table-striped tbody:nth-child(2n) tr td {
    background-color: rgba(0,0,21,.05);
}
.table-striped tbody:nth-child(2n+1) tr td {
    background-color: white;
} */
/* .table-hover tbody:nth-child(2n) tr:hover td,
.table-hover tbody:nth-child(2n) tr:hover th {
  background-color: #dae1e7;
}
.table-hover tbody:nth-child(2n+1) tr:hover td,
.table-hover tbody:nth-child(2n+1) tr:hover th {
  
  background-color: #ebedef;
} */
/* .parent-el-header{
        position: relative;
        height: 50px;
        width: 50px;
        align-items: center !important;
        justify-content: center !important;
    }
    .parent-el {
        position: relative;
        height: 50px;
        width: 50px;
    }
    .child-el {
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        height: inherit;
        width: -webkit-fill-available;
    } */
</style>
