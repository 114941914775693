<template>
  <base-row v-if="!$fnc.isArrayEmpty(files)">
    <base-col
      v-for="(item, index) in files"
      :key="item.data_storage_id"
      col="1/5"
      class="border-right"
      :class="{
        'pb-2 border-bottom': files.length > 5,
        'pt-2': index > 4
      }"
    >
      <base-img-viewer :file="item" file-id-key="data_storage_id" />
      <div class="my-2 flex align-items-center word-break-word">
        {{ item.original_file_name }}

        <base-file-download
          :files="[item]"
          file-id-key="data_storage_id"
          class="ml-2"
        >
          <i class="el-icon-download font-bold" title="Stáhnout soubor" />
        </base-file-download>
      </div>
      <div v-if="showCreatedBy">(Přidal: {{ item.createdby_name }})</div>
    </base-col>
  </base-row>
  <div v-else />
</template>

<script>
export default {
  name: 'BaseImgLayout',

  props: {
    files: {
      type: Array,
      default: () => []
    },
    showCreatedBy: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
