<template>
  <!-- Reusable modal component -->
  <vs-prompt
    ref="base-modal"
    class="base-modal"
    :active.sync="modalActive"
    append-to-body
    :title="title"
    buttons-hidden
    :class="[size, { 'no-padding': noPadding }]"
    @close="onClose"
  >
    <slot />
  </vs-prompt>
</template>

<script>
export default {
  name: 'BaseModal',

  props: {
    active: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: ''
    },

    size: {
      type: String,
      default: 'md'
    },

    closeOnBackdrop: {
      type: Boolean,
      default: true
    },

    noPadding: {
      type: Boolean,
      default: false
    },

    addBodyStyles: {
      type: [String],
      default: ''
    }
  },

  computed: {
    modalActive: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit('update:active', val);

        if (val) {
          this.$emit('open', val);
        } else {
          this.$emit('close', val);
        }
      }
    }
  },

  watch: {
    modalActive(val) {
      if (val) {
        this.$nextTick(() => this.setModalConfig());
      } else {
        this.unsetModalConfig();
      }
    },

    $route() {
      if (this.modalActive) {
        this.modalActive = false;
      }
    }
  },

  updated() {
    this.$nextTick(() => this.setDraggable());
  },

  mounted() {
    this.$nextTick(() => this.setDraggable());
  },

  methods: {
    setModalConfig() {
      const baseModal = this.$refs['base-modal'];
      if (baseModal) {
        // set body styles
        if (this.addBodyStyles) {
          baseModal.$el.lastElementChild.lastElementChild.style.cssText +=
            this.addBodyStyles;
        }
        // add modal close on esc
        const baseModalEl = baseModal.$el;
        baseModalEl.tabIndex = '0';
        this.$nextTick(() => {
          baseModalEl.focus();
          baseModalEl.addEventListener('keydown', this.addCloseOnEsc);
        });
      }

      // remove backdrop events (ten vuesax..)
      if (!this.closeOnBackdrop) {
        this.removeCloseOnBackdrop();
      }

      // Old approach:
      // close on esc
      // document.querySelector('*:not([disabled],[tabindex="-1"])').focus();
      // document.addEventListener('keydown', this.addCloseOnEsc);
      /* // Cleanup: Remove event listener when component is destroyed
      this.$once('hook:destroyed', () => {
        console.log('sure');
        document.removeEventListener('keydown', this.addCloseOnEsc);
      }); */
      // disable scrolling
      document.body.style['overflow-y'] = 'hidden';
    },

    unsetModalConfig() {
      const baseModal = this.$refs['base-modal'];
      if (baseModal) {
        const baseModalEl = baseModal.$el;
        baseModalEl.removeEventListener('keydown', this.addCloseOnEsc);

        // focus na dalsi modal pokud je otevreny
        //  resi problem pokud je otevrenych vice tabu
        const allOpenedModals = document.getElementsByClassName('base-modal');
        if (allOpenedModals && allOpenedModals.length > 1) {
          allOpenedModals[1].focus();
        }
      }

      // Old approach:
      // close on esc
      // document.removeEventListener('keydown', this.addCloseOnEsc);

      // disable scrolling
      document.body.style['overflow-y'] = 'auto';
    },

    onClose() {
      this.$emit('close');
    },

    checkBackdrop() {
      /* if (!this.closeOnBackdrop) {
        this.modalActive = true;
      } */
    },

    // Add close on esc
    addCloseOnEsc(e) {
      e.stopPropagation();

      if (e.key === 'Escape' || e.keyCode === 27) {
        this.modalActive = false;
      }
    },

    // Remove closing modal on backdrop
    removeCloseOnBackdrop() {
      const baseModal = this.$refs['base-modal'];
      if (baseModal.active) {
        // Remove all event listeners from the element
        const elBackdrop = baseModal.$el.firstElementChild;
        const clonedElement = elBackdrop.cloneNode(true);
        elBackdrop.parentNode.replaceChild(clonedElement, elBackdrop);
      }
    },

    // Make the DIV element draggable:
    setDraggable() {
      const baseModal = this.$refs['base-modal'];
      if (baseModal.active) {
        this.dragElement(baseModal.$el.lastElementChild);
      }
      /* if (document.getElementsByClassName('vs-dialog')[0]) {
        this.dragElement(document.getElementsByClassName('vs-dialog')[0]);
      } */
    },

    dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;

      const header = elmnt.firstElementChild; // document.getElementsByClassName('vs-dialog-header')[0]
      if (header) {
        // if present, the header is where you move the DIV from:
        header.onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        elmnt.classList.add('modal-drag-classes');
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
        elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  }
};
</script>

<style lang="scss">
.base-modal .vs-dialog .vs-dialog-text {
  max-height: calc(100vh - 70px);
  overflow-y: auto;
}
.base-modal.py-0 .vs-dialog .vs-dialog-text {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.base-modal.md .vs-dialog {
  max-width: 600px;
}
.base-modal.lg .vs-dialog {
  max-width: 900px;
}
.base-modal.xl .vs-dialog {
  max-width: 1300px;
}
.base-modal.xxl .vs-dialog {
  max-width: 1600px;
}
.modal-drag-classes {
  position: absolute;
  -webkit-transition: none;
  transition: none;
  margin: 0;
}
</style>

<style>
.no-padding.con-vs-dialog .vs-dialog .vs-dialog-text {
  padding: 0;
}
</style>
