/*=========================================================================================
  File Name: admin.js
  Description: actions requests to admin.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'admin';

const actions = {
  // Uzivatele
  users: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'users');

    // commit('set', ['masterdata', 'users', data.data['users']], { root: true });
    commit('set', ['masterdata', 'workgroups', data.data['workgroups']], {
      root: true
    });

    return data;
  },

  'users-detail': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'users-detail', payload);

    commit('set', ['masterdata', 'users', data.data['users']], { root: true });
    commit('set', ['masterdata', 'workgroups', data.data['workgroups']], {
      root: true
    });

    return data;
  },

  'users-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'users-save', payload);

    return data;
  },

  'users-simple-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'users-simple-save',
      payload
    );

    return data;
  },

  'users-remove': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'users-remove', payload);

    return data;
  },

  // Rangetypes
  rangetypes: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'rangetypes');

    commit('set', ['masterdata', 'rangetypes', data.data['rangetypes']], {
      root: true
    });

    return data;
  },

  'rangetypes-new': async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'rangetypes-new');

    commit('set', ['masterdata', 'annualprices', data.data['annualprices']], {
      root: true
    });

    return data;
  },

  'rangetypes-detail': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'rangetypes-detail', payload);

    commit('set', ['masterdata', 'annualprices', data.data['annualprices']], {
      root: true
    });
    commit('set', ['masterdata', 'jobtypes', data.data['jobtypes']], {
      root: true
    });
    commit('set', ['masterdata', 'indexes', data.data['indexes']], {
      root: true
    });

    return data;
  },

  'rangetypes-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'rangetypes-save', payload);

    return data;
  },

  'rangetypes-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'rangetypes-remove',
      payload
    );

    return data;
  },

  'rangetypes-duplicate': async (context, payload) => {
    const data = await Vue.$api.post(
      moduleName,
      'rangetypes-duplicate',
      payload
    );

    return data;
  },

  'rangetypes2-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'rangetypes2-save', payload);

    return data.saved;
  },

  'rangetypes2-remove': async (context, payload) => {
    await Vue.$api.delete(moduleName, 'rangetypes2-remove', payload);
  },

  'rangetypes2-items-sorting': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'rangetypes2-items-sorting',
      payload
    );

    return data;
  },

  // Roční ceny
  annualprices: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'annualprices');

    commit('set', ['masterdata', 'annualprices', data.data['annualprices']], {
      root: true
    });
    commit('set', ['masterdata', 'currencies', data.data['currencies']], {
      root: true
    });

    return data;
  },

  'annualprices-detail': async ({ commit }, payload) => {
    const data = await Vue.$api.index(
      moduleName,
      'annualprices-detail',
      payload
    );

    commit('set', ['masterdata', 'currencies', data.data['currencies']], {
      root: true
    });

    return data;
  },

  'annualprices-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'annualprices-save',
      payload
    );

    return data;
  },

  'annualprices-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'annualprices-remove',
      payload
    );

    return data;
  },

  // Naklady
  supplies: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'supplies');

    commit('set', ['masterdata', 'supplies', data.data['supplies']], {
      root: true
    });

    return data;
  },

  'supplies-new': async () => {
    const data = await Vue.$api.index(moduleName, 'supplies-new');

    return data;
  },

  'supplies-detail': async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'supplies-detail', payload);

    return data;
  },

  'supplies-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'supplies-save', payload);

    return data;
  },

  'supplies-remove': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'supplies-remove', payload);

    return data;
  },

  // Indexy
  indexes: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'indexes');

    commit('set', ['masterdata', 'indexes', data.data['indexes']], {
      root: true
    });
    commit(
      'set',
      ['masterdata', 'indexesGroups', data.data['indexes_groups']],
      { root: true }
    );

    return data;
  },

  'indexes-detail': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'indexes-detail', payload);

    commit('set', ['masterdata', 'firms', data.data['firms']], { root: true });

    return data;
  },

  'indexesgroups-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'indexesgroups-save',
      payload
    );

    return data;
  },

  'indexesgroups-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'indexesgroups-remove',
      payload
    );

    return data;
  },

  'indexes-save': async (context, payload) => {
    await Vue.$api.update(moduleName, 'indexes-save', payload);
  },

  'indexes-remove': async (context, payload) => {
    await Vue.$api.update(moduleName, 'indexes-remove', payload);
  },

  // Oddeleni
  workgroups: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'workgroups');

    commit('set', ['masterdata', 'workgroups', data.data['workgroups']], {
      root: true
    });

    return data;
  },

  'workgroups-detail': async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'workgroups-detail', payload);

    return data;
  },

  'workgroups-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'workgroups-save', payload);

    return data;
  },

  'workgroups-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'workgroups-remove',
      payload
    );

    return data;
  },

  // Typy ukolu
  jobtypes: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'jobtypes');

    commit('set', ['masterdata', 'jobtypes', data.data['jobtypes']], {
      root: true
    });

    return data;
  },

  'jobtypes-new': async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'jobtypes-new');

    commit('set', ['masterdata', 'workgroups', data.data['workgroups']], {
      root: true
    });

    return data;
  },

  'jobtypes-detail': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'jobtypes-detail', payload);

    commit('set', ['masterdata', 'workgroups', data.data['workgroups']], {
      root: true
    });

    return data;
  },

  'jobtypes-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'jobtypes-save', payload);

    return data;
  },

  'jobtypes-remove': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'jobtypes-remove', payload);

    return data;
  },

  'jobtypetypes-save': async (context, payload) => {
    await Vue.$api.update(moduleName, 'jobtypetypes-save', payload);
  },

  'jobtypetypes-remove': async (context, payload) => {
    await Vue.$api.delete(moduleName, 'jobtypetypes-remove', payload);
  },

  // Vykazy
  usersheet: async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'usersheet', payload);

    commit('set', ['masterdata', 'users', data.data['users']], {
      root: true
    });

    return data;
  },

  // Interni naklady
  supplysheet: async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'supplysheet', payload);

    return data.data;
  },

  'supplysheet-form': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'supplysheet-form', payload);

    commit('set', ['masterdata', 'supplies', data.data['supplies']], {
      root: true
    });
    commit('set', ['masterdata', 'orders', data.data['orders']], {
      root: true
    });

    return data.data;
  },

  'supplysheet-save': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'supplysheet-save', payload);

    return data.saved;
  },

  'supplysheet-remove': async (context, payload) => {
    await Vue.$api.delete(moduleName, 'supplysheet-remove', payload);
  },

  useroverheads: async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'useroverheads', payload);

    return data.data;
  },

  'useroverheads-save': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'useroverheads-save', payload);

    return data.saved;
  }
};

export default {
  namespaced: true,
  actions
};
