// import Vue from "vue"

/* route = path + section */
const defaultPaths = () => {
  return {
    main_page: { name: 'Úvod', path: '/', translation_key: 'intro', url: '' },
    login: { name: 'Login', path: '/pages/login/', alt_name: 'Přihlášení' },
    chat: { name: 'Chat', path: '/chat', url: '/chat' },

    /* Uzivatel */
    user_tasks: {
      name: 'Uživatel - Úkoly',
      path: '/user/tasks/',
      url: '/user/tasks/',
      alt_name: 'Uživatel'
    },
    user_usersheet: { name: 'Uživatel - Výkazy', path: '/user/usersheet/' },
    user_holiday: {
      name: 'Uživatel - Dovolená a práce na dálku',
      path: '/user/holiday/'
    },
    user_supplysheet: {
      name: 'Náklady uživatele',
      path: '/user/supplysheet/'
    },

    /* Zakázky */
    orders: { name: 'Zakázky', path: '/orders/', url: '/orders/' },

    /* Kalkulace */
    pricelists: {
      name: 'Ceník',
      path: '/pricelists/',
      url: '/pricelists/',
      alt_name: 'Ceník'
    },
    estimates: {
      name: 'Kalkulace',
      path: '/estimates/',
      url: '/estimates/',
      alt_name: 'Kalkulace'
    },
    estimates_templates: {
      name: 'Šablony',
      path: '/estimates/templates/',
      url: '/estimates/templates/',
      alt_name: 'Šablony'
    },

    /* Workflow */
    workflow: { name: 'Workflow', path: '/workflow/', url: '/workflow/' },

    /* Vykazy */
    usersheet: { name: 'Výkazy', path: '/usersheet/' },

    /* Dovolene - prehled */
    holidays_overview: {
      name: 'Dovolené - přehled',
      path: '/holidays-overview/'
    },

    /* Adresar */
    firms: { name: 'Adresář', path: '/firms/', url: '/firms/' },

    /* Administrace */
    users: { name: 'Uživatelé', path: '/admin/users/', url: '/admin/users/' },
    user_year_settings: {
      name: 'Čerpání dovolené',
      path: '/admin/user-year-settings/',
      url: '/admin/user-year-settings/'
    },
    rangetypes: {
      name: 'Rangetypes',
      path: '/admin/rangetypes/',
      url: '/admin/rangetypes/'
    },
    annualprices: {
      name: 'Roční ceny',
      path: '/admin/annualprices/',
      url: '/admin/annualprices/'
    },
    supplies: {
      name: 'Náklady',
      path: '/admin/supplies/',
      url: '/admin/supplies/'
    },
    useroverheads: {
      name: 'Overheads',
      path: '/admin/useroverheads/',
      url: '/admin/useroverheads/'
    },
    indexes: {
      name: 'Indexy',
      path: '/admin/indexes/',
      url: '/admin/indexes/'
    },
    workgroups: {
      name: 'Oddělení',
      path: '/admin/workgroups/',
      url: '/admin/workgroups/'
    },
    jobtypes: {
      name: 'Typy úkolů',
      path: '/admin/jobtypes/',
      url: '/admin/jobtypes/'
    },

    /* Notifikace */
    notifications: {
      name: 'Notifikace',
      path: '/notifications/',
      url: '/notifications/'
    }
  };
};

const paths = defaultPaths();

const sections = {
  list: { name: '', path: 'list' }, // - seznam
  new: { name: ' - nový', path: 'new' },
  detail: { name: ' - detail', path: 'detail&item=:id' }
};

export const getRoute = (path_key, section_key = false) => {
  let sectionName = '';
  let sectionPath = '';

  if (section_key !== false) {
    sectionName = sections[section_key].name;
    sectionPath = sections[section_key].path;
  }

  if (!Object.keys(paths).includes(path_key)) {
    // Vue.$fnc.notificationAlert('Daná route není definovaná!', 'warning');
    return paths.default;
  }

  return {
    name: paths[path_key].name + sectionName,
    path: paths[path_key].path + sectionPath,
    url:
      (paths[path_key].url ? paths[path_key].url : paths[path_key].path) +
      sectionPath,
    // url: paths[path_key].path + sectionPath,
    path_key: path_key,
    alt_name: paths[path_key].alt_name
  };
};

/* set last visited subsection path in each paths */
export const setLastRoute = (path_key, path) => {
  if (!Object.keys(paths).includes(path_key)) {
    // Vue.$fnc.notificationAlert('Daná route není definovaná! Nelze nastavit poslední navštívenou podsekci sekce!', 'warning');
    return false;
  }

  paths[path_key].url = path;
};
