/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Router from 'vue-router';
import { getRoute, setLastRoute } from '@/Routering.js';
import RouteSchema from './RouteSchema';

/* HLAVNI STRANKA */
const main_page = new RouteSchema('main_page', false).getBasicRoutes();

/* UZIVATEL */
const User = () => import('@/views/user/User');
// Ukoly
const UserTask = () => import('@/views/user/UserTask');
// Vykazy
const UserUsersheet = () => import('@/views/user/UserUsersheet');
// Dovolena
const UserHoliday = () => import('@/views/user/UserHoliday');
// Naklady
const UserSupplysheet = () => import('@/views/user/UserSupplysheet');

/* Dovolene - prehled */
const holidays_overview = new RouteSchema('holidays_overview').getBasicRoutes();

/* ZAKAZKY */
const orders = new RouteSchema('orders', true).getTabsRoutes();

/* KALKULACE */
// Ceník
const pricelists = new RouteSchema('pricelists', true).getTabsRoutes();
// Kalkulace
const estimates = new RouteSchema('estimates', true).getTabsRoutes();
// Šablony
const estimates_templates = new RouteSchema(
  'estimates_templates',
  true
).getTabsRoutes();

/* WORKFLOW */
const workflow = new RouteSchema('workflow', true).getBasicRoutes();

/* VYKAZY */
const usersheet = new RouteSchema('usersheet', true).getBasicRoutes();

/* ADRESÁŘ */
const firms = new RouteSchema('firms', true).getTabsRoutes();

/*  NASTAVENÍ  */
// Uzivatele
const users = new RouteSchema('users', true).getTabsRoutes();
// Rocni prehled
const user_year_settings = new RouteSchema(
  'user_year_settings',
  true
).getBasicRoutes();
// Rangetype
const rangetypes = new RouteSchema('rangetypes', true).getTabsRoutes();
// Roční ceny
const annualprices = new RouteSchema('annualprices', true).getTabsRoutes();
// Naklady
const supplies = new RouteSchema('supplies', true).getTabsRoutes();
// Overheads
const useroverheads = new RouteSchema('useroverheads', true).getBasicRoutes();
// Indexy
const indexes = new RouteSchema('indexes', true).getTabsRoutes();
// Oddělení
const workgroups = new RouteSchema('workgroups', true).getTabsRoutes();
// Typy úkolů
const jobtypes = new RouteSchema('jobtypes', true).getTabsRoutes();

// Notifikace
const notifications = new RouteSchema('notifications').getBasicRoutes();

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // View Routes
        // =============================================================================
        {
          path: '',
          component: () => import('@/views/ViewWrapper.vue'),
          children: [
            // =============================================================================
            // Component Routes
            // =============================================================================
            ...main_page,
            {
              path: getRoute('user_tasks').path, // '/uzivatel',
              name: 'Uživatel',
              component: User,
              meta: {
                path_key: 'user_tasks',
                admin: false,
                translation_key: 'user'
              },
              children: [
                {
                  ...getRoute('user_tasks'),
                  component: UserTask,
                  meta: {
                    path_key: 'user_tasks',
                    auth: true,
                    admin: false,
                    translation_key: 'tasks'
                  }
                },
                {
                  ...getRoute('user_usersheet'),
                  component: UserUsersheet,
                  meta: {
                    path_key: 'user_tasks',
                    auth: true,
                    admin: false,
                    translation_key: 'usersheet'
                  }
                },
                {
                  ...getRoute('user_holiday'),
                  component: UserHoliday,
                  meta: {
                    path_key: 'user_tasks',
                    auth: true,
                    admin: false,
                    translation_key: 'holiday'
                  }
                },
                {
                  ...getRoute('user_supplysheet'),
                  component: UserSupplysheet,
                  meta: {
                    path_key: 'user_tasks',
                    auth: true,
                    admin: false,
                    translation_key: 'supplysheet'
                  }
                }
              ]
            },
            /* ZAKAZKY */
            ...orders,
            /* KALKULACE */
            ...pricelists,
            ...estimates,
            ...estimates_templates,
            /* WORKFLOW */
            ...workflow,
            ...usersheet,
            ...holidays_overview,
            /* ADRESÁŘ */
            ...firms,
            /* ADMINISTRACE */
            ...users,
            ...user_year_settings,
            ...rangetypes,
            ...annualprices,
            ...supplies,
            ...useroverheads,
            ...indexes,
            ...workgroups,
            ...jobtypes,
            /* NOTIFIKACE */
            ...notifications,
            /* {
                      path: '/adresar/novy',
                      name: 'Adresář - Nový',
                      component: () => import('@/views/firm/components/FirmNew.vue')
                  }, */
            /* {
                      path: '/administrace/uzivatele',
                      name: 'Administrace - Uživatelé',
                      component: () => import('@/views/administration/users/Users.vue'),
                      meta: { 
                        admin: true,
                        translation_key: 'users' 
                      },
                  }, */

            {
              ...getRoute('chat'),
              component: () => import('@/views/chat/Chat.vue'),
              meta: {
                admin: false,
                translation_key: 'chat'
              }
            }
          ]
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          ...getRoute('login'),
          // name: getRoute('login').alt_name,
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/Register.vue')
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/pages/chat',
          name: 'Chat - ' + process.env.VUE_APP_TITLE + ' (ver. 1.0.0)',
          component: () => import('@/views/chat/Chat.vue'),
          meta: {
            admin: false
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
});

//const userData = Vue.$store.state.AppActiveUser

router.beforeEach(async (to, from, next) => {
  // kontrola prihlaseneho uzivatele
  if (Vue.$auth.getAccessToken() && Vue.$auth.getAccessToken().length > 0) {
    // pouze jednou po prvnim nacteni stranky
    // jisti to promenna isUserLogged (aby se neposilal pozadavek pri kazde zmene routu (sekce))
    // or
    // check if VUEX and localstorage tokens are the same
    // if not then request new user data
    // solves login as 2 different users in 1 browser
    if (!Vue.$store.state.isUserLogged) {
      await Vue.$store.dispatch('request-verify');

      // presmerovani uziv s oddelenimi view_all = N na stranku uzivatel
      if (
        !Vue.$store.getters['VIEW_ALL'] &&
        to.path === getRoute('main_page').path
      ) {
        //(Vue.$store.state.AppActiveUser.workgroupId != 1 && Vue.$store.state.AppActiveUser.workgroupId != 4)
        next(getRoute('user_tasks'));
      }
      //if(Vue.$store.state.AppActiveUser.workgroupId != 1) return next('/uzivatel')
    }

    if (Vue.$auth.getAccessToken() !== Vue.$store.state.access_token) {
      await Vue.$store.dispatch('request-verify');
    }
  }

  // jestli je prihlasen
  if (Vue.$store.state.isUserLogged) {
    // ZABRANENI MOZNOSTI PREJIT NA LOGIN POKUD JE UZIVATEL PRIHLASEN
    if (to.path === getRoute('login').path) {
      // alert("Už jste přihlášen.")
      next(getRoute('main_page').path);
    }

    if (to.meta.admin === true) {
      if (
        !Vue.$store.getters['VIEW_ALL'] &&
        !(
          to.meta.path_key == 'workflow' &&
          Vue.$store.getters['CAN_ACCESS_WORKFLOW']
        ) &&
        !(
          to.meta.path_key == 'usersheet' &&
          Vue.$store.getters['IS_WORKGROUP_LEADER']
        )
      ) {
        // alert("Ke vstupu nemáte dostatečná oprávnění!")
        next(getRoute('main_page').path);
      }
      if (
        !Vue.$store.getters['CAN_ACCESS_ADMIN'] &&
        to.path.includes('admin')
      ) {
        next(getRoute('main_page').path);
      }
    }

    /* nastaveni posledni navstivene podsekce */
    if (to.meta.path_key) {
      await setLastRoute(to.meta.path_key, to.path);
    }

    next();
  } else {
    // při špatném tokenu přesměruj na prihl. str.
    //alert("Špatný token!")

    if (to.path !== getRoute('login').path) {
      next(getRoute('login').path);
    } else {
      next();
    }
  }
});

router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
  // browser tab title
  Vue.nextTick(() => {
    Vue.$fnc.changeDocumentTitle(to);
  });
});

export default router;
