<template>
  <div @keyup.enter.stop>
    <!-- <vs-select
      v-model="inputValue"
      :name="name"
      autocomplete
      color="primary"
      :disabled="disabled"
      :class="{ 'is-changed': isChanged }"
      class="w-full"
      @change="$emit('change')"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
      <vs-select-item
        v-for="(opt, index) in selectOptions"
        :key="index"
        :value="opt.value"
        :text="opt.label"
        :class="[opt._classes]"
        :style="opt._styles"
      />
    </vs-select> -->
    <el-select
      ref="base-select"
      v-model="inputValue"
      :name="name"
      :clearable="clearable"
      :filterable="filterable"
      :allow-create="allowCreate"
      :size="size"
      :placeholder="placeholder"
      :disabled="disabled"
      :no-data-text="NO_DATA_TEXT"
      default-first-option
      :popper-class="popperClass"
      :class="[
        {
          'is-changed': isChanged,
          'is-hidden': chosenItem.hidden && !IS_DEFAULT_DATE(chosenItem.hidden),
          'is-default': !inputValue
        }
      ]"
      class="w-full position-relative"
      @focus="onFocus"
      @blur="onBlur"
      @change="onChange"
      @visible-change="onVisibleChange"
      :filter-method="filterMethod"
    >
      <el-option
        v-for="(opt, index) in selectOptionsWithSearchQuery"
        :key="index + '-' + opt[valueKey]"
        :label="String(opt[labelKey])"
        :value="
          $fnc.isNumber(opt[valueKey]) ? parseInt(opt[valueKey]) : opt[valueKey]
        "
        :disabled="opt.disabled"
      >
        <span
          :class="[
            { 'is-hidden': opt.hidden && !IS_DEFAULT_DATE(opt.hidden) },
            opt._classes
          ]"
          :style="opt._styles"
          style="float: left"
          >{{ opt[labelKey] }}</span
        >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'base-select',

  $_veeValidate: {
    // fetch the current value from the innerValue defined in the component data.
    value() {
      return this.inputValue;
    }
  },

  props: {
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: [String, Number],
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: true
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noDefaultValue: {
      type: Boolean,
      default: false
    },
    isChanged: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '-- vyberte --'
    },
    popperClass: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      searchQuery: ''
    };
  },

  computed: {
    ...mapGetters(['NO_DATA_TEXT', 'IS_DEFAULT_DATE']),

    chosenItem() {
      const item = this.options.find((i) => i.value == this.value);

      if (item) {
        return item;
      } else {
        return false;
      }
    },

    placeholderComp() {
      return this.placeholder;
    },

    // true = ciselnik ma hodnoty cisla (ID)
    //  kontrola podle prvniho zaznamu (TODO: fix)
    isOptionValuesNumber() {
      if (!this.$fnc.isArrayEmpty(this.options)) {
        const opts = this.options.filter((i) => !i.disabled);

        return opts && opts[0] && this.$fnc.isNumber(opts[0].value);
      }

      return false;
    },

    isValueNumber() {
      return this.$fnc.isNumber(this.value);
    },

    defaultValue() {
      let defaultValue = '';

      // NOVE: default NULL
      if (this.isOptionValuesNumber || this.inputValue === null) {
        defaultValue = '';
      }

      /* Old approach: // nastavime vychozi hodnotu = 0, pokud jsou hodnoty cisla (vetsinou idecka)
      //  nebo je samotna hodnota cislo
      if (
        (!this.$fnc.isArrayEmpty(this.options) &&
          this.$fnc.isNumber(this.options[0].value)) ||
        this.$fnc.isNumber(this.inputValue)
      ) {
        defaultValue = 0;
      } */

      return {
        label: this.placeholderComp,
        value: defaultValue
      };
    },

    // selekt s filtraci
    selectOptionsWithSearchQuery() {
      if (this.searchQuery) {
        const searchQuerySplit = this.$fnc
          .stringNoDiacritics(this.searchQuery)
          .toLowerCase()
          .split(/\s+/);

        return this.selectOptions.filter((i) => {
          // Split the document text into individual words (tokens) and convert them to lowercase
          const iLabels = this.$fnc
            .stringNoDiacritics(i.label)
            .toLowerCase()
            .split(/\s+/);

          // Check if every query token is a substring of any document token
          return searchQuerySplit.every((q) =>
            iLabels.some((t) => t.includes(q))
          );
        });
      } else {
        return this.selectOptions;
      }

      /* Old approach: if (this.searchQuery) {
        return this.selectOptions.filter(
          (i) =>
            !i.disabled &&
            (this.$fnc.matchLowerCase(i.label, this.searchQuery) ||
              this.$fnc.matchLowerCase(i.label_no_diacritics, this.searchQuery))
        );
      } else {
        return this.selectOptions;
      } */
    },

    selectOptions() {
      return this.noDefaultValue
        ? this.options
        : [this.defaultValue, ...this.options];
    },

    inputValue: {
      get() {
        return this.isValueNumber && this.value !== ''
          ? parseInt(this.value)
          : this.value; // this.value /* > 0 ? this.value : '' */;
      },

      set(val) {
        if (this.disabled) return;

        this.$emit('before-update', val);
        this.$emit('update:value', val);
        this.$nextTick(() => this.$emit('after-update', val));
      }
    }
  },

  /* mounted() {
    // pokud je hodnota = 0, tak nove prevedeme na '' (FK problem)
    if (
      this.isOptionValuesNumber &&
      this.isValueNumber &&
      parseInt(this.value) === 0
    ) {
      this.inputValue = '';
    }
  }, */

  methods: {
    filterMethod(query) {
      this.searchQuery = query;
    },

    focusManually() {
      setTimeout(() => {
        const baseSelect = this.$refs['base-select'];
        if (baseSelect) {
          //baseSelect.$el.firstElementChild.focus();
          baseSelect.focus();
          baseSelect.visible = true;
        }
      }, 100);
    },

    onFocus(e) {
      this.$emit('focus', e);
    },

    onBlur(e) {
      this.$emit('blur', e);
    },

    onChange(e) {
      this.$emit('change', e);
    },

    onVisibleChange(isvis) {
      if (isvis) {
        this.searchQuery = '';
      }
    },

    testme(e) {
      console.log(e);
    }
  }
};
</script>

<style lang="scss">
.is-changed .el-select .el-input__inner {
  border: 1px solid #ff9f43 !important;
}
.is-hidden {
  text-decoration: line-through;

  .el-input__inner {
    text-decoration: line-through;
  }
}
.is-default {
  .el-input__inner {
    color: rgba(0, 0, 21, 0.4);
  }
}
</style>
