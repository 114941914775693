/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {
  API_IN_PROGRESS: (state) => state.apiInProgress,

  // php api url
  API_URL: (state) => {
    return state.apiURL + '/';
  },

  APP_NAME: (state) => {
    return state.appName;
  },
  DEV_COMPANY_URL: (state) => {
    return state.devCompanyURL;
  },
  DEV_COMPANY_NAME: (state) => {
    return state.devCompanyName;
  },

  // is tesco
  IS_TESCO: (state) => (type) => {
    return String(state.tescoType) === String(type);
  },
  TESCO_TYPE: (state) => {
    return state.tescoType;
  },

  // user data
  APP_ACTIVE_USER: (state) => {
    return state.AppActiveUser;
  },
  USER_ID: (state) => {
    return state.AppActiveUser.id;
  },
  USER_WORKGROUP_ID: (state) => {
    return state.AppActiveUser.workgroup_id;
  },
  USER_WORKGROUP_NAME: (state) => {
    return state.AppActiveUser.workgroup_name;
  },
  USER_FULLNAME: (state) => {
    return state.AppActiveUser.name + ' ' + state.AppActiveUser.surname;
  },
  ACCESS_TOKEN: (state) => {
    return state.AppActiveUser.access_token;
  },

  // user permissions
  VIEW_ALL: (state) => {
    return Number(state.AppActiveUser.workgroup_id) ===
      Number(state.appSettings.admin_workgroup_id) ||
      state.AppActiveUser.view_all === 'Y'
      ? true
      : false;
  },
  IS_WORKGROUP_LEADER: (state) => {
    return state.AppActiveUser.workgroupleader === 'Y' ? true : false;
  },
  CAN_ACCESS_ADMIN: (state, getters) => {
    return getters['VIEW_ALL'];
  },
  CAN_ASSIGN_WORKFLOW: (state) => {
    return state.AppActiveUser.canassignworkflow === 'Y' ? true : false;
  },
  CAN_INSERT_USERSHEET: (state) => {
    return state.AppActiveUser.caninsertusersheet === 'Y' ? true : false;
  },
  CAN_APPROVE_WORKFLOW: (state) => {
    return state.AppActiveUser.canapproveworkflow === 'Y' ? true : false;
  },
  CAN_ASSIGN_WORKFLOW_ALL: (state) => {
    return state.AppActiveUser.canassignworkflowall === 'Y' ? true : false;
  },
  CAN_APPROVE_HOLIDAY: (state) => {
    return state.AppActiveUser.canapproveholiday === 'Y' ? true : false;
  },
  CAN_CONTROL_CORRECTION: (state) => {
    return state.AppActiveUser.cancontrolcorrection === 'Y' ? true : false;
  },
  CAN_CONTROL_TECHNIK: (state) => {
    return state.AppActiveUser.cancontroltechnik === 'Y' ? true : false;
  },
  CAN_EDIT_WORKFLOW: (state) => {
    return state.AppActiveUser.caneditworkflow === 'Y' ? true : false;
  },
  CAN_DELETE_ORDER: (state) => {
    return state.AppActiveUser.candeleteorder === 'Y' ? true : false;
  },
  CAN_EDIT_USERSHEET: (state) => {
    return state.AppActiveUser.caneditusersheet === 'Y' ? true : false;
  },
  CAN_EDIT_USERSHEET_ALL: (state) => {
    return state.AppActiveUser.caneditusersheetall === 'Y' ? true : false;
  },
  CAN_ACCESS_WORKFLOW: (state) => {
    return state.AppActiveUser.canaccessworkflow === 'Y' ? true : false;
  },
  CAN_INVOICE_ESTIMATE: (state) => {
    return state.AppActiveUser.caninvoiceestimate === 'Y' ? true : false;
  },

  // settings
  _PROJECT_FEE_PERCENTAGE: (state) =>
    state.appSettings['project_fee_percentage'] ?? '',

  DEFAULT_CURRENCY: (state) => state.appSettings.default_currency ?? '',
  DEFAULT_CURRENCY2: (state) => state.appSettings.default_currency2 ?? '',
  DEFAULT_LANGUAGE: (state) => state.appSettings.default_language ?? '',

  GRAFIK_WORKGROUP_ID: (state) => {
    return state.appSettings.grafik_workgroup_id ?? '';
  },
  ATELIER_WORKGROUP_ID: (state) => {
    return state.appSettings.atelier_workgroup_id ?? '';
  },
  DTP_WORKGROUP_IDS: (state) => {
    return [
      Number(state.appSettings.dtp_prepress_workgroup_id),
      Number(state.appSettings.dtp_artwork_workgroup_id),
      Number(state.appSettings.dtp_retouch_workgroup_id)
    ];
  },

  WORKFLOW_ACTIVE_STATUS_ID: (state) => {
    return state.appSettings.workflow_active_status_id ?? '';
  },
  WORKFLOW_ARCHIVED_STATUS_ID: (state) => {
    return state.appSettings.workflow_archived_status_id ?? '';
  },
  WORKFLOW_IN_REVIEW_STATUS_ID: (state) => {
    return state.appSettings.workflow_in_review_status_id ?? '';
  },
  WORKFLOW_DONE_STATUS_ID: (state) => {
    return state.appSettings.workflow_done_status_id ?? '';
  },
  WORKFLOW_IN_PROGRESS_STATUS_ID: (state) => {
    return state.appSettings.workflow_in_progress_status_id ?? '';
  },

  DEFAULT_ORDERSTATUS_ID: (state) => {
    return state.appSettings.default_orderstatus_id ?? '';
  },
  DEFAULT_ORDERTYPE_ID: (state) => {
    return state.appSettings.default_ordertype_id ?? '';
  },

  ESTIMATELINK_ACTIVE_STATUS_ID: (state) => {
    return state.appSettings.estimatelink_active_status_id ?? '';
  },
  ESTIMATELINK_TO_INVOICE_STATUS_ID: (state) => {
    return state.appSettings.estimatelink_to_invoice_status_id ?? '';
  },
  ESTIMATELINK_INVOICED_STATUS_ID: (state) => {
    return state.appSettings.estimatelink_invoiced_status_id ?? '';
  },

  RT2_COLUMN_TYPE_NOTE: (state) => {
    return state.appSettings.rt2_column_type_note ?? '';
  },

  MAX_FILE_SIZE: (state) => {
    return state.appSettings.max_file_size;
  },
  MAX_FILE_SIZE_EMAIL_TOTAL: (state) => {
    return state.appSettings.max_file_size_email_total;
  },
  MAX_FILE_UPLOADS: (state) => {
    return state.appSettings.max_file_uploads;
  },

  DEFAULT_DATETIME: (state) => {
    return state.default_datetime;
  },

  IS_DEFAULT_DATE: (state) => (val) => {
    return (
      !val ||
      val == state.default_date ||
      val == state.default_datetime ||
      val == '0000-00-00' ||
      val == '0000-00-00 00:00:00'
    );
  },

  WORKFLOW_DEFAULT_TIME: (state) => {
    return state.workflowDefaultTime;
  },

  HIDE_USER_PHOTOS: (state) => {
    return state.hideUserPhotos;
  },

  // for table
  NO_DATA_TEXT: (state) => {
    return state.noDataText;
  },
  MAX_ITEMS: (state) => {
    return state.maxItems;
  },

  EXTENTIONS_ALLOWED: (state) => {
    return state.extensionsAllowed;
  },

  ORDER_HIDDEN_RT2: (state) => (order_id) => {
    return state.AppActiveUser.hiddenRangeTypes2[order_id] || [];
  },

  SCROLL_Y: (state) => {
    return state.scrollY;
  },

  WINDOW_WIDTH: (state) => {
    return state.windowWidth;
  },

  VERTICAL_NAV_MENU_WIDTH: (state) => {
    return state.verticalNavMenuWidth;
  },

  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: (state) => {
    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return 'xl';
    else if (state.windowWidth >= 992) return 'lg';
    else if (state.windowWidth >= 768) return 'md';
    else if (state.windowWidth >= 576) return 'sm';
    else return 'xs';
  },

  scrollbarTag: (state) => {
    return state.is_touch_device ? 'div' : 'VuePerfectScrollbar';
  }
};

export default getters;
